import { Backdrop, Fade, makeStyles, Modal } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme)=>({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const ModalComponent = ({children, modalOpen, handleCloseModal}) => {

    const classes = useStyles();

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalOpen}
                onClose={handleCloseModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalOpen}>
                    {children}
                </Fade>
            </Modal>
        </>
    )
};

export default ModalComponent;

import React from "react"

function TipsHover({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 232.053 247.16"
    >
      <g fill="#499fdd">
        <path d="M116.028 59.743c-30.489 0-55.294 24.805-55.294 55.294 0 18.304 9.052 35.398 24.213 45.725.343.233.661.515.935.829 1.436 1.633 8.599 11.353 8.599 42.222v26.476a7.019 7.019 0 007.01 7.012h29.074c3.866 0 7.011-3.145 7.011-7.012v-26.476c0-31.512 7.276-40.816 8.735-42.36a4.92 4.92 0 01.808-.691c15.156-10.33 24.204-27.423 24.204-45.725 0-30.489-24.806-55.294-55.295-55.294zm14.537 187.417h-29.073c-9.302 0-16.87-7.568-16.87-16.87v-26.477c0-24.646-4.788-33.602-5.927-35.382-17.435-12.211-27.82-32.107-27.82-53.394 0-35.926 29.229-65.152 65.153-65.152 35.925 0 65.153 29.226 65.153 65.152 0 21.29-10.386 41.184-27.829 53.394-1.117 1.748-5.917 10.697-5.917 35.382v26.476c0 9.303-7.568 16.871-16.87 16.871" />
        <path d="M142.505 214.453H89.552a4.93 4.93 0 010-9.858h52.953a4.929 4.929 0 110 9.858M25.972 120.962H4.929a4.929 4.929 0 110-9.86h21.043a4.93 4.93 0 010 9.86M227.128 120.962h-21.044a4.93 4.93 0 010-9.86h21.044a4.93 4.93 0 010 9.86M37.469 199.52a4.926 4.926 0 01-3.485-8.413l14.879-14.88a4.928 4.928 0 116.97 6.97l-14.879 14.88a4.912 4.912 0 01-3.485 1.443M179.708 57.281a4.927 4.927 0 01-3.485-8.413l14.88-14.88a4.929 4.929 0 016.969 6.97l-14.879 14.88a4.914 4.914 0 01-3.485 1.443M116.028 30.905a4.93 4.93 0 01-4.93-4.929V4.933a4.93 4.93 0 019.86 0v21.043a4.93 4.93 0 01-4.93 4.93M194.588 199.52a4.914 4.914 0 01-3.485-1.444l-14.88-14.88a4.927 4.927 0 010-6.97 4.928 4.928 0 016.97 0l14.879 14.88a4.924 4.924 0 010 6.97 4.909 4.909 0 01-3.484 1.444M52.349 57.281a4.915 4.915 0 01-3.486-1.444L33.983 40.96a4.928 4.928 0 010-6.971 4.93 4.93 0 016.971 0l14.88 14.88a4.928 4.928 0 01-3.485 8.413M77.865 119.287a4.93 4.93 0 01-4.93-4.93c0-22.837 18.58-41.418 41.419-41.418a4.93 4.93 0 010 9.858c-17.403 0-31.56 14.158-31.56 31.56a4.93 4.93 0 01-4.93 4.93" />
      </g>
    </svg>
  )
}

export default TipsHover
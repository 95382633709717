import React, { useEffect, useState } from "react";
import Menubar from "../../common/menubar/Menubar";
import { Grid, Paper } from "@material-ui/core";

const Home = ({ children, GeneratedProgramParam }) => {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    var isSafariVar =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    console.log("Is Safari ===> ", isSafariVar);
    setIsSafari(isSafariVar);
  }, []);
  return (
    <div className="Body-class">
      <Grid container>
        <Grid item xs />
        <Grid item xs={12} lg={8} xl={4} sm={10}>
          <Paper
            elevation={3}
            square
            className={
              isSafari === true ? "main-paper-safari" : "main-paper-other"
            }
          >
            {children}
          </Paper>
        </Grid>
        <Grid item xs />
      </Grid>
      {/* {!GeneratedProgramParam && <Menubar />} */}
    </div>
  );
};

export default Home;

import * as React from "react"


function ProgramListIcon({size}) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 256 256"
  >
    <path d="M99.8 47.1c-1.3.7-1.8 2.3-1.8 5.9 0 4.7.2 5 2.5 5 2.2 0 2.5-.4 2.5-3.5V51h82v122h-3.5c-3.1 0-3.5.3-3.5 2.5 0 2.3.3 2.5 4.8 2.5 2.6 0 5.3-.5 6-1.2.9-.9 1.2-16.6 1.2-64.4 0-56.2-.2-63.4-1.6-64.8-1.4-1.4-6.7-1.6-44.2-1.6-27.1 0-43.3.4-44.4 1.1z" />
    <path d="M83.2 63.2c-.7.7-1.2 3.4-1.2 6 0 4.5.2 4.8 2.5 4.8 2.2 0 2.5-.4 2.5-3.5V67h82v122h-3.5c-3.1 0-3.5.3-3.5 2.5 0 2.3.3 2.5 4.8 2.5 2.6 0 5.3-.5 6-1.2 1.7-1.7 1.7-127.9 0-129.6-1.7-1.7-87.9-1.7-89.6 0z" />
    <path d="M67.2 79.2c-.9.9-1.2 16.6-1.2 64.4 0 56.2.2 63.4 1.6 64.8 1.4 1.4 6.7 1.6 44 1.6 27.4 0 43.2-.4 44.5-1 1.8-1 1.9-2.8 1.9-64.8 0-48.3-.3-64.1-1.2-65-1.7-1.7-87.9-1.7-89.6 0zM153 144v61H71V83h82v61z" />
    <path d="M82 112.5v2.5h60v-5H82v2.5zM82.4 126.5c-.4.8-.4 2.2 0 3 .5 1.3 4.4 1.5 29.8 1.3l29.3-.3v-5l-29.3-.3c-25.4-.2-29.3 0-29.8 1.3zM82 143.5v2.5h60v-5H82v2.5zM82 159.5v2.5h60v-5H82v2.5zM82 175.5v2.5h60v-5H82v2.5z" />
  </svg>
  )
}

export default ProgramListIcon

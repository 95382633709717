import React from "react"

function ListOfExe({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 231.693 231.24"
    >
      <path
        d="M21.011 14.004c-3.862 0-7.003 3.141-7.003 7.003v189.221c0 3.861 3.141 7.004 7.003 7.004h142.906a7.012 7.012 0 007.004-7.004V21.007c0-3.862-3.141-7.003-7.004-7.003zm142.906 217.234H21.011C9.426 231.238 0 221.813 0 210.228V21.007C0 9.42 9.426-.004 21.011-.004h142.906c11.587 0 21.012 9.425 21.012 21.01v189.222c0 11.585-9.425 21.01-21.012 21.01"
        fill="#100f0d"
      />
      <path
        d="M145.008 63.253H39.921a7.004 7.004 0 010-14.006h105.087a7.003 7.003 0 110 14.006M119.909 92.937H38.353c-3.001 0-5.435-3.136-5.435-7.004 0-3.866 2.434-7.004 5.435-7.004h81.556c3 0 5.436 3.138 5.436 7.004 0 3.868-2.435 7.004-5.436 7.004M94.81 122.621H36.786c-2.136 0-3.867-3.136-3.867-7.004 0-3.868 1.73-7.003 3.867-7.003H94.81c2.135 0 3.867 3.135 3.867 7.003 0 3.868-1.732 7.004-3.867 7.004M69.712 152.305H35.217c-1.27 0-2.3-3.136-2.3-7.004 0-3.868 1.03-7.004 2.3-7.004h34.495c1.27 0 2.299 3.136 2.299 7.004 0 3.868-1.03 7.004-2.299 7.004M44.614 181.99H33.648c-.403 0-.73-3.138-.73-7.005 0-3.868.327-7.004.73-7.004h10.966c.403 0 .73 3.136.73 7.004 0 3.867-.327 7.004-.73 7.004"
        fill="#100f0d"
      />
      <path
        d="M150.17 155.237l-19.385 20.788-21.912-20.788M130.725 110.02v59.316"
        fill="none"
        stroke="#100f0d"
        strokeWidth={9.3331}
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default ListOfExe

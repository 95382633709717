import * as React from "react"

function HomeIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 535 441"
    >
      <path d="M24.7 1.4C15.4 4.8 8.1 12.9 3.8 24.5c-3.2 8.6-3.2 26.4 0 35 4.4 12 13 21.1 22.1 23.4 2.1.6 102.7 1.3 242.6 1.7 222.3.6 239.3.5 243-1 9.8-4.1 16.5-11.6 20.6-23.1 3.3-8.9 3.2-26.6-.1-35-4.6-11.9-12.7-20.5-21.5-23C502.9.4 30.3-.7 24.7 1.4zM23.4 179.6c-8.7 3.1-16.9 13-20.6 24.7-2.2 7.1-2.6 20.4-.8 28.1 2.6 11.6 9.7 21.8 18.4 26.4l5.1 2.7 239.9.9c220.6.8 240.4.7 245.2-.8 9.7-3 18.6-13.7 21.9-26.1 1.8-7 2.1-20 .5-26.9-2.6-11.6-10-22.2-18.6-26.6l-4.9-2.5-141-.6c-256.1-1.1-340.4-.9-345.1.7zM22.3 357.9c-6.4 3-10 6-13.8 11.5-6 8.8-7.8 15.5-7.8 28.6-.1 12.2 1.6 18.7 6.9 27.1 3.6 5.8 11.2 11.8 16.8 13.3 4.3 1.2 42.8 1.5 245.1 1.8l240 .3 4.2-2.3c20.9-11.1 26.8-45.9 11.7-67.9-3.9-5.7-10.7-10.8-16.3-12.2-2.1-.6-102.3-1.2-243.1-1.6-237.2-.6-239.5-.6-243.7 1.4z" />
    </svg>
  )
}

export default HomeIcon
import React from 'react';
import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    paper: {
        width: props => props.width,
        height:props => props.height,
        overflow:props => props.overflow,
        // scrollbarWidth:props=>props.scrollbarWidth,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: "10px",
        padding: theme.spacing(3, 4, 3),
        "&:focus": {
            outline: 'none'
        }
    },
    mobilePaper:{
        width: props => props.mobileWidth,
        height:props => props.mobileHeight,
        overflow:props => props.mobileOverflow,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: "10px",
        padding: theme.spacing(1, 2, 1),
        "&:focus": {
            outline: 'none'
        }
    }
}));

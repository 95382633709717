import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const Languages = ["english", "swedish", "finnish","spanish","buser"];

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    whitelist: Languages,
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath:
    //     "https://optimum-pdf-storage.s3.eu-north-1.amazonaws.com/english.json",
    // },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// import React from "react";
// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// import { useDispatch, useSelector } from "react-redux";
// // import { SELECT_LANGUAGE, STORE_JSON_DATA } from "../";
// import { languageRequests } from "../components/common/apiStructure/requests";
// import { instance } from "../components/common/apiStructure/axios";
// import _ from "underscore";

// import english from "../translation/en.json";
// //import { english } from "./english.js";

// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
// // don't want to use this?
// // have a look at the Quick start guide
// // for passing in lng and translations on init

// //const Languages = ["en", "sw"];

// const Languages = ["english", "french"];

// //const data = english();
// //console.log("Data =======>",data);

// const resources = {
//   en: {
//     translation: english
//   },
//   // hi: {
//   //   translation: common_hi
//   // }
// };
// console.log("resources ===> ", resources);

// i18n
//   .use(initReactI18next)
//   .init({
//     resources: resources,
//     lng: 'en',
//     keySeparator: false,
//     interpolation: {
//       escapeValue: false
//     }
//   })


// // i18n
// //   // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
// //   // learn more: https://github.com/i18next/i18next-http-backend
// //   .use(Backend)
// //   // detect user language
// //   // learn more: https://github.com/i18next/i18next-browser-languageDetector
// //   .use(LanguageDetector)
// //   // pass the i18n instance to react-i18next.
// //   .use(initReactI18next)
// //   // init i18next
// //   // for all options read: https://www.i18next.com/overview/configuration-options
// //   .init({
// //     //fallbackLng: "en",
// //     // customHeaders: {
// //     //   "Access-Control-Allow-Origin":"*"
// //     // },
// //     fallbackLng: "english",
// //     debug: true,
// //     whitelist: Languages,
// //     // backend: {
// //     //   // for all available options read the backend's repository readme file
// //     //   loadPath:
// //     //     "https://optimum-pdf-storage.s3.eu-north-1.amazonaws.com/english.json",
// //     // },  
// //     // crossDomain: true,
// //     // backend: {
// //     //   loadPath: function () {
// //     //     return "https://optimum-pdf-storage.s3.eu-north-1.amazonaws.com/english.json"
// //     //   },
// //     //   crossDomain: true
// //     // },
// //     interpolation: {
// //       escapeValue: false, // not needed for react as it escapes by default
// //     },
// //     // requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
// //     //   mode: 'cors',
// //     //   credentials: '*',
// //     //   cache: 'default'
// //     // }
// //   });

// export default i18n;

import * as React from "react"

function ForwardExeHover({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 301.173 231.24"
    >
      <path
        d="M21.011 14.004c-3.862 0-7.003 3.141-7.003 7.003v189.221c0 3.861 3.141 7.004 7.003 7.004h142.906a7.012 7.012 0 007.004-7.004V21.007c0-3.862-3.141-7.003-7.004-7.003zm142.906 217.234H21.011C9.426 231.238 0 221.813 0 210.228V21.007C0 9.42 9.426-.004 21.011-.004h142.906c11.587 0 21.012 9.425 21.012 21.01v189.222c0 11.585-9.425 21.01-21.012 21.01"
        fill="#499fdd"
      />
      <path
        d="M145.008 63.253H39.921a7.004 7.004 0 010-14.006h105.087a7.003 7.003 0 110 14.006M145.008 92.937H39.921a7.004 7.004 0 010-14.008h105.087a7.005 7.005 0 010 14.008M145.008 122.621H39.921a7.004 7.004 0 010-14.007h105.087a7.004 7.004 0 110 14.007M145.008 152.305H39.921a7.004 7.004 0 010-14.008h105.087a7.004 7.004 0 010 14.008M145.008 181.99H39.921a7.005 7.005 0 010-14.009h105.087a7.004 7.004 0 010 14.008"
        fill="#499fdd"
      />
      <path
        d="M295.843 114.948c0 35.43-28.722 64.152-64.152 64.152-35.431 0-64.152-28.721-64.152-64.152 0-35.43 28.721-64.152 64.152-64.152 35.43 0 64.152 28.723 64.152 64.152"
        fill="#fff"
      />
      <path
        d="M295.843 114.948c0 35.43-28.722 64.152-64.152 64.152-35.431 0-64.152-28.721-64.152-64.152 0-35.43 28.721-64.152 64.152-64.152 35.43 0 64.152 28.723 64.152 64.152z"
        fill="none"
        stroke="#499fdd"
        strokeWidth={10.6664}
        strokeMiterlimit={10}
      />
      <path
        d="M218.852 82.241l28.736 31.474-28.736 35.577"
        fill="none"
        stroke="#499fdd"
        strokeWidth={9.3331}
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default ForwardExeHover

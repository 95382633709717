import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router-dom";
import { suspenseFallbackLoader } from "./components/common/loaders/Loaders";
import { instance } from "./components/common/apiStructure/axios";
import { buserRequests } from "./components/common/apiStructure/requests";
import { Box, Button, Typography } from "@mui/material";
import {
  EMPTY_PARTICULAR_BUSER_PROGRAM,
  SET_BUSERTOKEN,
  SET_IDS_BUSER_PROGRAM,
} from "./redux/actions";

const BuserRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = "none";
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const loginToken = params.get("loginToken");
    const therapistId = params.get("therapistId");
    const clientId = params.get("clientId");
    const clinicId = params.get("clinicId");

    const verifyUser = async () => {
      try {
        const res = await instance.get(`${buserRequests.verifyBuserUser}`, {
          headers: {
            Authorization: `Bearer ${loginToken}`,
          },
        });
        //console.log(res, "loginres");
        if (res.status === 200) {
          dispatch(EMPTY_PARTICULAR_BUSER_PROGRAM());
          dispatch(SET_BUSERTOKEN(res.data.data?.token));
          if (clientId && therapistId) {
            dispatch(
              SET_IDS_BUSER_PROGRAM({ therapistId, clientId, clinicId })
            );
          }
          setVerified(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    verifyUser();

    return () => {
      document.body.style.backgroundImage = "none";
    };
  }, []);

  return loading ? (
    <>{suspenseFallbackLoader()}</>
  ) : verified ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        margin: "auto",
        height: "700px",
        gap: "20px",
      }}
    >
      <Typography variant="h4">
        Your Session has Expired Or Something Went Wrong!
      </Typography>
      <Button
        marginTop="20px"
        variant="contained"
        onClick={() => {
          window.parent.postMessage({ type: "Error" }, "*");
        }}
      >
        Try Again!
      </Button>
    </Box>
  );
};

export default BuserRoute;

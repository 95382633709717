import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "./DialogBox.scss";

function DialogBox({
  open,
  title,
  children,
  handleClose,
  handleAdd,
  isEdit,
  handleUpdate,
  handleOK,
  type,
  handleNo,
  handleYes,
}) {
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {type === "saveClose" ? (
            <>
              <div className="group_button">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  margin="dense"
                  className="close_button"
                >
                  {t("Close")}
                  {/* <img className="img_icon" src="https://img.icons8.com/metro/52/ffffff/multiply.png" /> */}
                </Button>
                <Button
                  onClick={handleAdd}
                  variant="contained"
                  margin="dense"
                  className="save_button"
                >
                  {t("Save")}
                  {/* <img className="img_icon" src="https://img.icons8.com/ios/52/ffffff/save--v1.png" /> */}
                </Button>
              </div>
            </>
          ) : type === "okClose" ? (
            <>
              <div className="group_button">
                <Button
                  onClick={handleOK}
                  variant="contained"
                  margin="dense"
                  className="save_button"
                >
                  {t("Ok")}
                </Button>
              </div>
            </>
          ) : type === "YesNo" ? (
            <>
              <div className="group_button">
                <Button
                  onClick={handleNo}
                  variant="contained"
                  margin="dense"
                  className="close_button"
                >
                  {t("No")}
                </Button>
                <Button
                  onClick={handleYes}
                  variant="contained"
                  margin="dense"
                  className="save_button"
                >
                  {t("Yes")}
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="group_add_update_cancel">
                <Button
                  onClick={handleClose}
                  variant="contained"
                  margin="dense"
                  color="primary"
                >
                  {t("Cancel")}
                </Button>
                {isEdit === true ? (
                  <Button
                    onClick={handleUpdate}
                    variant="contained"
                    margin="dense"
                    color="primary"
                  >
                    {t("Update")}
                  </Button>
                ) : (
                  <Button
                    onClick={handleAdd}
                    variant="contained"
                    margin="dense"
                    color="primary"
                  >
                    {t("Add")}
                  </Button>
                )}
              </div>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogBox;

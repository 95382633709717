import React from 'react';
import CreateDraft from './CreateDraft'

function CreateDraftForSelf(){
  return (
    <React.Fragment>
      <CreateDraft selfDraft={true}/>
      </React.Fragment>
  )
}

export default CreateDraftForSelf;
import React, { useState, useRef } from "react";

import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import "./Menubar.scss";

import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { AddClient } from "../../pages/client/addClient/AddClient";
import PeopleIcon from "@material-ui/icons/People";

import SearchIcon from '@material-ui/icons/Search';

import _, { truncate } from "lodash";
import { searchFn } from "../apiStructure/axios";
import { SET_CLIENT_DATA, SET_SEARCH_INPUT_VALUE } from "../../../redux/actions";
import {
  exerciseRequests,
  clientRequests,
} from "../../common/apiStructure/requests";
import { instance } from "../../common/apiStructure/axios";
import { errorToaster } from "../../../utils/CommonFunctions";

import AllClient from "../svg/AllClient.js";
import AllclientActive from "../svg/AllclientActive";

import AddClientSvg from "../svg/AddClient";
import AddClientActive from "../svg/AddClientActive";

import Exercises from "../svg/Exercises";
import ExercisesActive from "../svg/ExercisesActive";

import SearchIconSvg from "../svg/SearchIcon";
import SearchIconActive from "../svg/SearchIconActive";

import HomeIcon from "../svg/HomeIcon";
import HomeIconActive from "../svg/HomeIconActive";




const currentTab = (history, path) => {
  if (history.location.pathname.includes(path)) {
    return "active";
  } else {
    return "inActive";
  }
};

const Menubar = ({ history,
  placeholder,
  programListButton,
  setPrograms,
  selfProgramParam,
  clientId,
  createExerciseButton,
  setExercise,
  searchExeList,
  AddTips,
  CreateDraft,
  searchQueryNull,
  setSearchQueryNull,
  showFilterVersionButtons,
  versionButtons,
  filterByVersionFn,
  currentVersionIndex,
  type,
  setSearchQueryCheck,
  AddExerciseInProgram,
  sendPdfToUser,
  updateSortDataandexe,
  setUpdateSortDataandexe,
  userQueryNull,
  setUserQueryNull,
  tempNameForProgram,
  setTempNameForProgram,
  AllExercise,
  nullAndNoExe,
  ShowAddClient,
  searchActiveStatus,
  onlyExeList,
  //setExercises, // for only exercise list component
  searchingVal // for only exercise list component

}) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const searchVal = useSelector((state) => state.searchVal);
  const [modalOpen, setModalOpen] = useState(false);
  const [searchActive, setSearchaActive] = useState("inActive");

  let inputEl = useRef(null);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState({});
  const [sort, setSort] = useState(1);
  const [sortedExercise, setSortedExercise] = useState([]);
  const [tempExe, setTempExe] = useState([]);
  const [tempUser, setTempUser] = useState([]);

  const dispatch = useDispatch();
  const program = useSelector((state) => state.particularProgramData);
  const language = useSelector((state) => state.language);

  const handleChangeStatus = () => {
    if (searchActive === "active") {
      setSearchaActive("inActive");
    } else {
      setSearchaActive("active");
      console.log("inputEl ===> ", (inputEl));
      //inputEl.current.autofocus = true;
    }
  }

  const handleChange = (e) => {   
    const value = e.target.value;
    let filtered = [];
    if (createExerciseButton) {
      dispatch(SET_SEARCH_INPUT_VALUE(value));
      if (value.length === 0) {
        setExercise([]);
        setSearchQueryCheck(false);
        nullAndNoExe(true);
      }
      // else if (value.length === 1) {
      //   console.log("tempExe ===> ",tempExe);
      //   setExercise(tempExe);
      //   setSearchQueryCheck(true);
      // } 
      else {
        setSearchQueryCheck(true);

        // filtered = tempExe.list.filter((exe) => {
        //   return exe.abbreviation.toLowerCase() === value.toLowerCase();
        // });
        // if (filtered.length === 0) {
        //   filtered = tempExe.list.filter((exe) => {
        //     return (
        //       exe.name.toLowerCase().includes(value.toLowerCase()) ||
        //       exe.abbreviation.toLowerCase().includes(value.toLowerCase())
        //     );
        //   });
        // }

        tempExe.list.map((exe) => {
          let exe_name = exe.name.toLowerCase();
          let exe_abbrivation = exe.abbreviation.toLowerCase();
          if (exe_name.startsWith(`${value.toLowerCase()}`)) {
            filtered.push(exe);
          }
          if (exe_abbrivation.startsWith(`${value.toLowerCase()}`)) {
            filtered.push(exe);
          }
        })
        const body = {
          list: _.uniqBy(filtered, '_id'),
          totalPage: tempExe.totalPage,
        };
        setExercise(body);
      }
    } else if (programListButton) {
      let clientFilter;

      if (value.length === 0) {
        dispatch(SET_CLIENT_DATA(tempUser));
      } else if (value.length === 1) {
        let filterList = [];
        tempUser.map((client, i) => {
          let clientFname = client.firstname.toLowerCase();
          if (clientFname.startsWith(`${value.toLowerCase()}`)) {
            filterList.push(client);
          }
        })
        dispatch(SET_CLIENT_DATA(filterList));
      }
      else if (value.length >= 2 && value[1] != " ") {
        let filterList = [];
        tempUser.map((client, i) => {
          let clientFname = client.firstname.toLowerCase();
          if (clientFname.startsWith(`${value.toLowerCase()}`)) {
            filterList.push(client);
          }
        })
        dispatch(SET_CLIENT_DATA(filterList));

      }
      else if (value.length === 2 && value[1] === " ") {
        let filterList = [];
        tempUser.map((client, i) => {
          let clientFname = client.firstname.toLowerCase();
          if (clientFname.startsWith(`${value[0].toLowerCase()}`)) {
            filterList.push(client);
          }
        })
        dispatch(SET_CLIENT_DATA(filterList));

      }
      else if (value.length > 2 && value[1] === " ") {
        let filterList = [];
        tempUser.map((client, i) => {
          let clientFname = client.firstname.toLowerCase();
          let clientLname = client.lastname.toLowerCase();
          if (clientFname.startsWith(`${value[0].toLowerCase()}`) && clientLname.startsWith(`${value[2].toLowerCase()}`)) {
            filterList.push(client);
          }
        })
        dispatch(SET_CLIENT_DATA(filterList));
      }
      // else {
      //   clientFilter = tempUser.filter((user) => {
      //     return (
      //       user.firstname.toLowerCase().includes(value.toLowerCase()) ||
      //       user.lastname.toLowerCase().includes(value.toLowerCase())
      //     );
      //   });
      //   dispatch(SET_CLIENT_DATA(clientFilter));
      // }
    }
    else if (onlyExeList) {
      searchingVal(value);
    }
    else {
      const search = _.debounce(sendQuery, 100);

      setSearchQuery((prevSearch) => {
        if (prevSearch.cancel) {
          prevSearch.cancel();
        }
        return search;
      });

      search(value);
    }
    setQuery(value);
  };

  

  const sendQuery = async (value) => {
    const { cancelPrevQuery, result } = await searchFn(
      value,
      auth.token,
      auth.user._id,
      clientId,
      programListButton,
      selfProgramParam,
      createExerciseButton
    );
    if (cancelPrevQuery) return;
    if (result) {
      // if (programListButton) {
      //   dispatch(SET_CLIENT_DATA(result.data));
      // } else
      if (createExerciseButton) {
        if (value.length > 0) {
          setExercise(result.data);
          setSortedExercise(result.data);
        } else {
          setExercise([]);
          setSortedExercise([]);
        }
      } else {
        setPrograms(result.data);
      }
    }
  };

  const ClientSearch = async () => {
    try {
      const response = await instance.get(
        `${clientRequests.searchClient}/${auth.user._id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      dispatch(SET_CLIENT_DATA(response.data.data));
      setTempUser(response.data.data);
      //searchData();
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  // useEffect(() => {
  //   if (searchActiveStatus === true) {
  //     setSearchaActive("active");
  //   }
  // })

  // useEffect(() => {
  //   console.log("inputEl", inputEl.curren);
  //   ClientSearch();
  //   if (inputEl.current) {
  //     inputEl.current.focus();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (searchQueryNull === true) {
  //     setQuery("");
  //     setExercise([]);
  //     setSearchQueryNull(false);
  //     //inputEl.current.focus();
  //   }
  // }, [searchQueryNull]);

  // useEffect(() => {
  //   if (userQueryNull === true) {
  //     setQuery("");
  //     ClientSearch();
  //     //inputEl.current.focus();
  //     setUserQueryNull(false);
  //   }
  // }, [userQueryNull]);

  // useEffect(() => {
  //   if (type === "CreateDraft") {
  //     setQuery(program.programId);
  //   }
  // }, [type]);

  // useEffect(() => {
  //   if (updateSortDataandexe === true) {
  //     searchForClientsPExe();
  //     setUpdateSortDataandexe(false);
  //   }
  // }, [updateSortDataandexe]);

  // useEffect(() => {
  //   searchForClientsPExe();
  //   if(searchVal && createExerciseButton){
  //     setQuery(searchVal);
  //     //searchedData();
  //   }
  // }, []);

  // useEffect(()=> {
  //   if( createExerciseButton && tempExe.totalPage > 0 && searchVal!="" ){
  //     searchedData();
  //   }
  //   if(searchVal===""){
  //     setQuery("");
  //   }
  // },[tempExe])

  const searchedData = () => {
   if(tempExe.totalPage  > 0){
    let filtered = [];
    
    setSearchQueryCheck(true);
    tempExe.list.map((exe) => {
      let exe_name = exe.name.toLowerCase();
      let exe_abbrivation = exe.abbreviation.toLowerCase();
      if (exe_name.startsWith(`${query.toLowerCase()}`)) {
        filtered.push(exe);
      }
      if (exe_abbrivation.startsWith(`${query.toLowerCase()}`)) {
        filtered.push(exe);
      }
    })
    const body = {
      list: _.uniqBy(filtered, '_id'),
      totalPage: tempExe.totalPage,
    };
    setExercise(body);
   } 
  }

  const searchForClientsPExe = async () => {
    try {
      let requestBody =
        auth.user.hasOwnProperty("level") && auth.user.level === 500
          ? {
            therapistId: auth.user._id,
            // clientId: clientId ? clientId : "",
            clientId: "",
            level: auth.user.hasOwnProperty("level") ? auth.user.level : 100,
            adminId: "5fe46e36bde6076a09c2c6ff",
          }
          : {
            therapistId: auth.user._id,
            level: auth.user.hasOwnProperty("level") ? auth.user.level : 100,
            // clientId: clientId ? clientId : "",
            clientId: "",
            adminId: "5fe46e36bde6076a09c2c6ff",
          };


      const response = await instance.post(
        `${exerciseRequests.fetchGlobalExercises}?language=${language}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      console.log("response.data.data ====> ", response.data.data);
      //   let sortingData = 
      //   response.data.data.sort((a, b) => {
      //     let fa = a.name.toLowerCase(),
      //         fb = b.name.toLowerCase();

      //     if (fa < fb) {
      //         return -1;
      //     }
      //     if (fa > fb) {
      //         return 1;
      //     }
      //     return 0;
      // });
      setSortedExercise(response.data.data);
      setTempExe(response.data.data);
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleKeyDown = (event) => {
    if (createExerciseButton) {
      if (searchExeList && searchExeList.length && searchExeList.length === 1) {
        if (event.key === "Enter") {
          AddExerciseInProgram(searchExeList[0], event);
        }
      } else if (searchExeList && searchExeList.length) {
        if (event.key === "Enter") {
          const matchExe = searchExeList.filter(exe => exe.abbreviation === query);
          console.log("matchExe ==> ", matchExe);
          if (matchExe.length === 1) {
            AddExerciseInProgram(matchExe[0], event);
          }
        }
      }
    }
  };

  return (
    <>
      <div className="ul_class">
        {
          searchActive === "active" &&
          <>
            <div className="search_box" xs={12} lg={8} xl={5} sm={10}
              style={{display:"none"}}
            >
              <SearchIcon className="search_icon" />
              <input
                disabled={tempExe ? false : true}
                value={query}
                onChange={(e) => handleChange(e)}
                onKeyDown={(e) => {
                  handleKeyDown(e);
                }}
                type="text"
                placeholder={placeholder}
                ref={inputEl}
                autoFocus={searchActive === "active" ? true : false}
              //autoFocus={AddTips && tempExe.length === 0 ? true : false}
              />
            </div>
          </>
        }

        <ul className="ul">
          <li className={currentTab(history, "/account")}>
            <Link style={{ textDecoration: "none" }} to="/account">
              <div className="icon">
                {/* <AccountCircleIcon /> */}
                {currentTab(history, "/account") == "active" ? (
                  <HomeIconActive size={28} />
                  // <img
                  //   className="img_icon_first"
                  //   src={homeIconHover}
                  // />
                ) : (
                  <HomeIcon size={28} />
                  // <img
                  //   className="img_icon_first"
                  //   src={homeIcon}
                  // />
                )}

              </div>
              <div className="name">
                {/* {auth ? auth.user.firstName : "username"} */}
              </div>
            </Link>
          </li>

          {/* <li className={searchActive}>
            <Link style={{ textDecoration: "none" }} >
              <div className="icon" onClick={() => handleChangeStatus()} > */}
                {/* <PeopleIcon /> */}
                {/* {searchActive === "active" ? (
                  <SearchIconActive size={26} />
                  // <SearchIcon
                  //   className="img_icon"
                  //   onClick={() => handleChangeStatus()}
                  // />
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/fluent-systems-regular/32/006CA0/group2.png"
                  //   src={searchIconHover}
                  // />

                ) : (
                  <SearchIconSvg size={26} />
                  // <SearchIcon
                  //   className="img_icon"
                  //   onClick={() => handleChangeStatus()}
                  // />
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/fluent-systems-regular/32/006CA0/group2.png"
                  //   src={searchIcon}
                  // />
                )}
              </div> */}
              {/* <div className="name">{t("Clients")}</div> */}
            {/* </Link>
          </li> */}


          <li className={currentTab(history, "/clients")}>
            <Link style={{ textDecoration: "none" }} to="/clients">
              <div className="icon">
                {/* <PeopleIcon /> */}
                {currentTab(history, "/clients") == "active" ? (
                  <AllclientActive size={30} />
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/fluent-systems-regular/32/006CA0/group2.png"
                  //   src={allClientHover}
                  // />
                ) : (
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/fluent-systems-regular/32/000000/group2.png"
                  //   src={allClient}
                  // />
                  <AllClient size={30} />
                )}
              </div>
              {/* <div className="name">{t("Clients")}</div> */}
            </Link>
          </li>

          <li className={modalOpen === true ? "active" : "inActive"} onClick={() => setModalOpen(true)}>
            <Link style={{ textDecoration: "none" }}>
              <div className="icon">
                {/* <AddIcon /> */}
                {
                  modalOpen === true ?
                    // <img
                    //   className="img_icon"
                    //   // src="https://img.icons8.com/pastel-glyph/64/000000/add-user-male--v2.png"
                    //   src={addClientHover}
                    // />
                    <AddClientActive size={30} />
                    :
                    <AddClientSvg size={30} />
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/pastel-glyph/64/000000/add-user-male--v2.png"
                  //   src={addClient}
                  // />
                }

              </div>
              {/* <div className="name">{t("Client")}</div> */}
            </Link>
          </li>
          <li className={currentTab(history, "/exercises")}>
            <Link style={{ textDecoration: "none" }} to="/exercises">
              <div className="icon">
                {/* <DirectionsRunIcon /> */}
                {currentTab(history, "/exercises") == "active" ? (
                  <ExercisesActive size={30} />
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/ios-filled/50/006CA0/gymnastics.png"
                  //   src={exercisesHover}
                  // />
                ) : (
                  <Exercises size={30} />
                  // <img
                  //   className="img_icon"
                  //   // src="https://img.icons8.com/ios-filled/50/000000/gymnastics.png"
                  //   src={exercises}
                  // />
                )}
              </div>
              {/* <div className="name">{t("Exercises")}</div> */}
            </Link>
          </li>


        </ul>
      </div>
      <AddClient modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
    </>
  );
};

export default withRouter(Menubar);
